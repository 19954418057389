import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import Blocks from "gatsby-component-lib/components-legacy/_blocks"
import OContentHeader from "../../../../../gatsby-component-lib/components-legacy/organisms/o-content-header"

const DefaultPage = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    subnavigation,
    footerPageNodeContent,
  } = pageContext
  const { t } = useTranslation()
  let { breadcrumbs } = pageContext

  const content = JSON.parse(data.typo3Page.content)
  const metadata = content.metadata

  if (!breadcrumbs) {
    breadcrumbs =
      content && content.parents && content.parents.length
        ? content.parents.reverse()
        : [{ name: t("Websites." + website), full_slug: "/" }]
  }

  return (
    <>
      <OLayout
        tree={tree}
        website={website}
        website_lang={website_lang}
        subnavigation={subnavigation}
        breadcrumbs={breadcrumbs}
        website_theme={website_theme}
        footerPageNodeContent={footerPageNodeContent}
      >
        <TSeo
          website_theme={website_theme}
          website={website}
          content={content}
          full_slug={full_slug}
        />
        <main className="ef-content">
          <OContentHeader
            data={{
              kicker: content.kicker,
              headline: content.headline ? content.headline : content.title,
              intro: content.subline,
            }}
          />
          <article className="ef-article">
            <div className="ef-article__body">
              {content.body &&
                content.body.map &&
                content.body.map(block =>
                  Blocks[block.component]
                    ? React.createElement(Blocks[block.component], {
                        key: block.uid,
                        data: block,
                        pageData: {},
                      })
                    : `Block ${block.component} in ${block.uid} not created yet`
                )}
            </div>
          </article>
        </main>
        <MShare
          title={metadata ? metadata.title : ""}
          website={website}
          disabled={
            content && content.disable_share_button
              ? content.disable_share_button
              : 0
          }
        />
      </OLayout>
    </>
  )
}

export const query = graphql`
  query($uid: String!) {
    typo3Page(uid: { eq: $uid }) {
      content
    }
  }
`

export default DefaultPage
