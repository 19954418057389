import React from "react"
import { concatStrings } from "../../utilities/utilities"
import AHeading from "../atoms/a-heading"

const OContentHeader = props => {
  return (
    <>
      {props.data.headline && (
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            {props.data.kicker && (
              <div className="ef-kicker">
                <div className="ef-kicker__item">{props.data.kicker}</div>
              </div>
            )}
            <AHeading
              headline={props.data.headline}
              add_class={props.data.kicker && "ef-heading--has-kicker"}
            />
            {props.data.intro && (
              <div className="ef-container__narrow-m">
                <p className="ef-paragraph ef-paragraph--lead">
                  {props.data.intro}
                </p>
              </div>
            )}
          </div>
        </header>
      )}
    </>
  )
}

export default OContentHeader
